import {
  Button,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useSelector } from "react-redux";
import store from "../redux/store";
import { fullSliderwidth } from "../redux/slice/otherSlice";
import Sidebar from "./Sidebar";
import { HiMenu } from "react-icons/hi";
import CustomPopup from "./CustomPopup";
import { useNavigate } from "react-router-dom";

const Header = React.memo( () => {
  const { sidebar_width } = useSelector((state) => state.other);
  const { isOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isLOpen,
    onClose: onLClose,
    onOpen: onLOpen,
  } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Flex h={"full"} w={"full"}>
      <Center
        w={"13rem"}
        minW={"13rem"}
        borderRight={"1px solid #88888822 "}
        display={["none", "none", "none", "flex"]}
      >
        <Image
          src="logo.png"
          h={"12"}
          borderRadius={3}
          p={1}
          w={"10rem"}
          bg={"#727b82"}
          alt="LOGO"
        />
      </Center>
      <Flex
        display={["flex", "flex", "flex", "none"]}
        alignItems={"center"}
        pl={2}
        pr={10}
        w={"full"}
        justify={"space-between"}
      >
        <HiMenu size={25} cursor={"pointer"} onClick={() => onToggle()} />
        <Button variant={"link"} onClick={onLOpen}>
          Log Out
        </Button>
      </Flex>
      <Flex
        alignItems={"center"}
        pl={2}
        pr={10}
        w={"full"}
        justify={"space-between"}
        display={["none", "none", "none", "flex"]}
      >
        {sidebar_width ? (
          <AiOutlineMenuFold
            size={25}
            cursor={"pointer"}
            onClick={() => {
              store.dispatch(fullSliderwidth());
            }}
          />
        ) : (
          <AiOutlineMenuUnfold
            size={25}
            cursor={"pointer"}
            onClick={() => {
              store.dispatch(fullSliderwidth());
            }}
          />
        )}
        <Button variant={"link"} onClick={onLOpen}>
          Log Out
        </Button>
      </Flex>

      <Stack display={["none", "none", "none", "flex"]}>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          // finalFocusRef={btnRef}
          size={"xs"}
        >
          <DrawerOverlay />
          <DrawerContent onClick={() => onClose()} maxW={"52"}>
            <Sidebar />
          </DrawerContent>
        </Drawer>
      </Stack>
      <CustomPopup
        isOpen={isLOpen}
        onClose={onLClose}
        mess={"Are you sure? You want to log out"}
        title={"Log Out"}
        b_name={"Log Out"}
        onClick={() => {
          localStorage.removeItem("userId");
          onClose();
          navigate("/login");
        }}
      />
    </Flex>
  );
});

export default Header;
