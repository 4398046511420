import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBlog, FaReplyAll } from "react-icons/fa";
import { MdQueryStats } from "react-icons/md";

const Sidebar = React.memo(() => {
  return (
    <VStack align={"start"}>
      <NavLink to="/" icon={MdQueryStats}>
        Queries
      </NavLink>
      <NavLink to="/blog" icon={FaBlog}>
        Blog
      </NavLink>
      <NavLink to="/testimonial" icon={FaReplyAll}>
        Testimonial
      </NavLink>
    </VStack>
  );
});

const NavLink = ({ to, icon: Icon, children }) => {
  const location = useLocation();
  const isActive = location.pathname.split("/")[1] === to.split("/")[1];
  return (
    <Link to={to} className={isActive ? "activelink" : "link"}>
      <Icon size={20} />
      {children}
    </Link>
  );
};

export default Sidebar;
