import { toast } from "react-toastify";
import { base_url } from "./utils";


export const update_queries = async(body,setLoading)=>{
  try {
    setLoading && setLoading(true);
    const response = await fetch(base_url + "api/contact_us.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading && setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.log("error ==", error);

    setLoading && setLoading(false);
  }
}

export const add_testimonial = async (body, setLoading) => {
  try {
    setLoading && setLoading(true);
    const response = await fetch(base_url + "api/testimonial.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading && setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.log("error ==", error);

    setLoading && setLoading(false);
  }
};

export const add_blog = async (body, setLoading) => {
  try {
    setLoading && setLoading(true);
    const response = await fetch(base_url + "api/blog.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading && setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    console.log("error ==", error);

    setLoading && setLoading(false);
  }
};

export const login_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/adminlogin.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("userId", JSON.stringify(res));
      window.location.reload();
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};


