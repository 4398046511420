import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useFetchApi from "../../Hooks/useFetchApi";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import ImageModal from "../../components/ImageModal";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { delete_blog } from "../../redux/slice/BlogSlice";
import TableHeader from "../../components/TableHeader";
import useDebounce from "../../Hooks/useDebounce";

const Blog = () => {
  // States
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 800);

  // Hooks
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "api/blog.php",
    formDataArr: {
      action: "list",
      limit: rows,
      page: page,
      value: debouncedSearch,
    },
  });

  useEffect(() => {
    handleFetchData();
  }, [page, rows, debouncedSearch]);
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const navigate = useNavigate();

  // Functions

  const onDelete = useCallback(async () => {
    await store.dispatch(delete_blog(id)).then((data) => {
      const res = data?.payload;
      if (res.success) {
        toast.success(res.message);
        handleFetchData();
      }
    });
  }, [id]);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Blogs List</Heading>
        <CustomButton
          title={"Add Blog"}
          onClick={() => navigate("/add-blog", { state: { type: "Add" } })}
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Blogs: {data?.data?.length}
        </Text>
      </Flex>

      {data?.totalCount > 0 && (
        <TableHeader
          filter={<></>}
          exprtData={false}
          placeholder={`Search Blog`}
          value={search}
          onSerchChange={(v) => {
            setpage(1);
            setRows(10);
            setSearch(v.target.value);
          }}
        />
      )}

      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          pagination={true}
          rows={rows}
          page={page}
          onNext={() => {
            if (
              page <
              Math.ceil((search ? data?.searchCount : data?.totalCount) / rows)
            ) {
              setpage(page + 1);
            }
          }}
          onPrevious={() => {
            if (page > 1) {
              setpage(page - 1);
            }
          }}
          onChange={(e) => {
            setpage(1);
            setRows(e.target.value);
          }}
          total_value={search ? data?.searchCount : data?.totalCount}
          headData={[
            "S.No.",
            "Title",
            "Description",
            "Meta Description",
            "Image",
            "Alt Text",
            "Actions",
          ]}
          body={data?.data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
              <Td textAlign={"center"}>{v?.title}</Td>
              {/* <Td textAlign={"center"}>{v?.description}</Td> */}
              <Td textAlign={"center"}>
                <p
                  style={{
                    maxWidth: "300px",
                    maxHeight: "150px",
                    overflowY: "auto", // Enables scrolling when content overflows
                    wordWrap: "break-word", // Ensures words break to fit within max width
                    overflowWrap: "break-word", // Fallback for older browsers
                  }}
                  dangerouslySetInnerHTML={{ __html: v?.description }}
                ></p>
              </Td>
              <Td textAlign={"center"}>
                <p
                  style={{
                    maxWidth: "300px",
                    maxHeight: "150px",
                    overflowY: "auto",
                  }}
                >
                  {v?.meta_description}
                </p>
              </Td>
              <Td
                textAlign={"center"}
                cursor={"pointer"}
                onClick={() => {
                  onViewOpen();
                  setUrl(v?.image);
                }}
              >
                <Center>
                  <img width={"100px"} src={v?.image} alt={v?.alt_text} />
                </Center>
              </Td>
              <Td textAlign={"center"}>{v?.alt_text}</Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.id);
                    }}
                  />
                  <FiEdit3
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      navigate("/add-blog", {
                        state: { type: "Edit", item: v },
                      });
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Blog"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal
        // vid={true}
        isOpen={isViewOpen}
        onClose={onViewClose}
        url={url}
      />
    </Container>
  );
};

export default Blog;
