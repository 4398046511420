import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";

const SelectImage =  React.memo(({
  onChange,
  label,
  url,
  filename,
  mt,
  error,
  errorBorder,
  doctype,
}) => {
  const inputOpenFileRef = React.createRef();
  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };

  return (
    <FormControl flex={1} mt={mt || 0}>
      <FormLabel marginBottom={2}>{label}</FormLabel>

      {url &&
        (url?.name?.split(/[#?]/)[0]?.split(".")?.pop().trim() == "pdf" || (
          //   <iframe
          //     src={`https://docs.google.com/gview?url=${url}&embedded=true`}
          //     style={{ width: "600px", height: "500px" }}
          //     frameborder="0"
          //   ></iframe>
          // ) : (
          <Image
            src={url.name ? URL.createObjectURL(url) : url}
            h={"200px"}
            w={"xs"}
            borderRadius={8}
            objectFit={"cover"}
            overflow={"hidden"}
            mb={2}
          />
        ))}
      <HStack
        border={"1px solid #EBEAEA"}
        borderRadius={5}
        h={"10"}
        alignItems={"center"}
        onClick={showOpenFileDlg}
        overflow={"hidden"}
        borderColor={errorBorder && "#f00"}
      >
        <Text
          bg={"#EBEAEA"}
          h={10}
          m={0}
          paddingInline={8}
          textAlign={"center"}
          alignItems={"center"}
          pt={2}
          fontSize={"md"}
          fontWeight={"medium"}
        >
          Browse
        </Text>
        <Text>{filename || "Choose file"}</Text>
        <Input
          ref={inputOpenFileRef}
          type="file"
          onChange={onChange}
          display={"none"}
          accept={doctype || "image/png, image/jpg, image/jpeg ,.pdf"}
        />
      </HStack>
      {error && (
        <Text fontSize={"sm"} color={"#f00"}>
          {error}
        </Text>
      )}
    </FormControl>
  );
});

export default SelectImage;
