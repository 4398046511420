import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useFetchApi from "../../Hooks/useFetchApi";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import ImageModal from "../../components/ImageModal";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { delete_testimonial } from "../../redux/slice/TestimonialSlice";
import { add_testimonial } from "../../utils/apis";
import { FeedBackbar } from "../../components/LayoutUtils";

const Testimonial = () => {
  // States
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [url, setUrl] = useState("");

  // Hooks
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "api/testimonial.php",
    formDataArr: { action: "list", limit: rows, page: page },
  });
  useEffect(() => {
    handleFetchData();
  }, [page, rows]);

  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const navigate = useNavigate();

  // Functions

  const onDelete = useCallback(async () => {
    await store.dispatch(delete_testimonial(id)).then((data) => {
      const res = data?.payload;
      if (res.success) {
        toast.success(res.message);
        handleFetchData();
      }
    });
  }, [id]);

  const updatePriority = async (priority, id) => {
    if (priority != 0) {
      const priorityValidation = data?.data
        ?.filter((val) => val?.id != id)
        ?.some((val) => val?.priority == priority);
      if (priorityValidation) {
        toast.error(
          "This priority has already been selected. Please try again."
        );
        return;
      }
    }
    try {
      const body = new FormData();
      body.append("action", "update_priority");
      body.append("priority", priority);
      body.append("id", id);
      add_testimonial(body).then((v) => v.success && handleFetchData());
    } catch (error) {
      console.log("error ===", error);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Testimonials List</Heading>
        <CustomButton
          title={"Add Testimonial"}
          onClick={() =>
            navigate("/add-testimonial", { state: { type: "Add" } })
          }
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Testimonials: {data?.totalCount}
        </Text>
      </Flex>

      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          pagination={true}
          rows={rows}
          page={page}
          onNext={() => {
            if (page < Math.ceil(data?.totalCount / rows)) {
              setpage(page + 1);
            }
          }}
          onPrevious={() => {
            if (page > 1) {
              setpage(page - 1);
            }
          }}
          onChange={(e) => {
            setpage(1);
            setRows(e.target.value);
          }}
          total_value={data?.totalCount}
          headData={["S.No.", "Name", "Rating", "Note", "Priority", "Actions"]}
          body={data?.data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
              <Td textAlign={"center"}>{v?.name}</Td>
              <Td textAlign={"center"}>
                {/* {v?.rating} */}
                <Flex justifyContent={"center"}>
                  {" "}
                  <FeedBackbar rating={v?.rating} />
                </Flex>
              </Td>
              <Td
                textAlign={"center"}
                style={{
                  maxWidth: "300px",
                  maxHeight: "150px",
                  overflowY: "auto", // Enables scrolling when content overflows
                  wordWrap: "break-word", // Ensures words break to fit within max width
                  overflowWrap: "break-word", // Fallback for older browsers
                }}
              >
                {v?.short_note}
              </Td>
              <Td>
                {" "}
                <Select
                  placeholder=""
                  size={"md"}
                  w={"15"}
                  value={v?.priority}
                  onChange={(e) => {
                    updatePriority(e.target.value, v?.id);
                  }}
                >
                  {Array(data?.data?.length + 1)
                    .fill()
                    ?.map((val, ind) => (
                      <option value={ind}>{ind}</option>
                    ))}
                </Select>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.id);
                    }}
                  />
                  <FiEdit3
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      navigate("/add-testimonial", {
                        state: { type: "Edit", item: v },
                      });
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Testimonial"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal
        vid={true}
        isOpen={isViewOpen}
        onClose={onViewClose}
        url={url}
      />
    </Container>
  );
};

export default Testimonial;
