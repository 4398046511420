import {
  Card,
  Center,
  Container,
  Flex,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeadingText from "../../components/HeadingText";
import { BackButton, Description } from "../../components/LayoutUtils";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { add_blog } from "../../utils/apis";

const AddBlog = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});
  const { state } = useLocation();
  const { type, item } = state;

  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const validation = () => {
    if (
      !Obj?.title ||
      !Obj?.description ||
      (!Obj?.image?.name && type === "Add") ||
      !Obj?.alt_text ||
      !Obj?.meta_description
    ) {
      setObj({
        ...Obj,
        titleval: !Obj?.title,
        titlemess: !Obj?.title ? "Title is required" : "",

        descriptionval: !Obj?.description,
        descriptionmess: !Obj?.description ? "Description is required" : "",

        imageval: !Obj?.image?.name && type === "add",
        imagemess:
          !Obj?.image?.name && type === "add" ? "Image is required" : "",

        alt_textval: !Obj?.alt_text,
        alt_textmess: !Obj?.alt_text ? "Alt text is required" : "",
        metaval: !Obj.meta_description,
        metamess: !Obj?.meta_description ? "Meta Description is required" : "",
      });
      return;
    } else {
      submitData();
    }
  };

  // create(title,description,alt_text,image)

  const submitData = () => {
    const body = new FormData();
    body.append("action", type == "Edit" ? "update" : "create");
    if (type == "Edit") {
      body.append("id", item?.id);
    }
    body.append("title", Obj?.title);
    body.append("description", Obj?.description);
    body.append("meta_description", Obj?.meta_description);
    body.append("alt_text", Obj?.alt_text);
    if (Obj?.image?.name) {
      body.append("image", Obj?.image);
    }
    add_blog(body, setLoading).then((v) => v.success && navigate(-1));
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={`${type} Blog`} />
        <BackButton />
      </Flex>

      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          mb={10}
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />
          <SelectImage
            mt={2}
            label={"Blog Image"}
            filename={Obj?.image?.name}
            onChange={(e) => setObj({ ...Obj, image: e.target.files[0] })}
          />

          <CustomInput
            label1={"Alt Text"}
            placeholder1={"Enter Alt Text"}
            value1={Obj.alt_text}
            onChange1={(e) =>
              setObj({
                ...Obj,
                alt_text: e.target.value,
                alt_textval: false,
                alt_textmess: "",
              })
            }
            onlyone
            errorBorder1={Obj.alt_textval}
            error1={Obj.alt_textmess}
          />
          <CustomInput
            area1
            label1={"Meta Description"}
            placeholder1={"Enter Meta Description"}
            value1={Obj?.meta_description}
            onChange1={(e) =>
              setObj({
                ...Obj,
                meta_description: e.target.value,
                metaval: false,
                metamess: "",
              })
            }
            onlyone
            errorBorder1={Obj?.metaval}
            error1={Obj?.metamess}
          />
        </Grid>
        <Description
          label={"Description"}
          value={Obj?.description}
          onChange={(content) =>
            setObj((prev) => ({
              ...prev,
              description: content,
              descriptionMess: "",
            }))
          }
          error={Obj?.descriptionMess}
        />
        <Center mt={10}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_video()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddBlog;
