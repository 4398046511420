import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";

const CustomPopup =  React.memo(({
  isOpen,
  onClose,
  onClick,
  title,
  mess,
  b_name,
  b_color,
  single_button,
}) => {
  const cancelRef = React.useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" maxH={"12"}>
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{mess}</AlertDialogBody>

          <AlertDialogFooter>
            {single_button ? (
              <CustomButton
                bg={"gray"}
                title={b_name || "Cancel"}
                // ref={cancelRef}
                onClick={onClose}
              />
            ) : (
              <Flex gap={3}>
                <CustomButton
                  bg={"gray"}
                  title={"Cancel"}
                  // ref={cancelRef}
                  onClick={onClose}
                />
                <CustomButton
                  bg={b_color || "red"}
                  title={b_name || "Delete"}
                  // ref={cancelRef}
                  onClick={onClick}
                />
              </Flex>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});

export default CustomPopup;
