import { Spinner, Stack } from "@chakra-ui/react";
import React from "react";

const Loader =  React.memo(() => {
  return (
    <Stack
      w={"full"}
      h={"24"}
      borderRadius={10}
      alignItems={"center"}
      justify={"center"}
      bg={"AppWorkspace"}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Stack>
  );
});

export default Loader;
