import {
  Card,
  Center,
  Container,
  Flex,
  Grid,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import { BackButton, FeedBack } from "../../components/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { add_testimonial } from "../../utils/apis";

const AddTestimonial = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});
  const { state } = useLocation();
  const { type, item } = state;

  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const validation = () => {
    if (!Obj?.name || !Obj?.short_note || !Obj?.rating) {
      setObj({
        ...Obj,
        nameval: !Obj?.name ? true : false,
        namemess: !Obj?.name ? "Name is required" : "",

        noteval: !Obj?.short_note ? true : false,
        notemess: !Obj?.short_note ? "Note is required" : "",

        ratingval: !Obj?.rating ? true : false,
        ratingmess: !Obj?.rating ? "Rating is required" : "",
      });
      return;
    } else {
      submitData();
    }
  };

  const submitData = () => {
    const body = new FormData();
    body.append("action", type == "Edit" ? "update" : "create");
    if (type == "Edit") {
      body.append("id", item?.id);
    } else {
      body.append("priority", 0);
    }
    body.append("name", Obj?.name);
    body.append("short_note", Obj?.short_note);
    body.append("rating", Obj?.rating);
    add_testimonial(body, setLoading).then((v) => v.success && navigate(-1));
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={`${type} Testimonial`} />
        <BackButton />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            label1={"Name"}
            placeholder1={"Enter Name"}
            value1={Obj?.name}
            onChange1={(e) =>
              setObj({
                ...Obj,
                name: e.target.value,
                nameval: false,
                namemess: "",
              })
            }
            onlyone
            errorBorder1={Obj?.nameval}
            error1={Obj?.namemess}
          />

          <FeedBack
            data={Obj}
            setData={setObj}
            error={Obj?.ratingmess}
            title={"Feedback"}
          />
          <CustomInput
            area1
            label1={"Note"}
            placeholder1={"Enter Note"}
            value1={Obj?.short_note}
            onChange1={(e) =>
              setObj({
                ...Obj,
                short_note: e.target.value,
                noteval: false,
                notemess: "",
              })
            }
            onlyone
            errorBorder1={Obj?.noteval}
            error1={Obj?.notemess}
          />
        </Grid>
        <Center mt={10}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_video()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddTestimonial;
