import { configureStore } from "@reduxjs/toolkit";
import otherSlice from "./slice/otherSlice";
import { dataApi } from "./RTKQuery/dataApi";

const store = configureStore({
  reducer: {
    other: otherSlice,
    [dataApi.reducerPath]: dataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(dataApi.middleware),
});
export default store;
