import { useState, useEffect, useRef } from "react";

const useSessionTimeout = (isLoggedIn, initialTimeout = 60, onSessionExpire = () => {}) => {
  const [sessionExpire, setSessionExpire] = useState(false);
  const timeoutRef = useRef(initialTimeout);
  const timerRef = useRef(null);

  

  const resetTimer = () => {
    if (!isLoggedIn) return;
    timeoutRef.current = initialTimeout;
    localStorage.setItem("lastActivity", Date.now());
    startTimer();
  };

  const startTimer = () => {
    if (!isLoggedIn || timerRef.current) return;
    stopTimer(); 
    timerRef.current = setInterval(() => {
      const lastActivity = parseInt(localStorage.getItem("lastActivity") || Date.now(), 10);
      const timeSinceLastActivity = Math.floor((Date.now() - lastActivity) / 1000);

      timeoutRef.current = initialTimeout - timeSinceLastActivity;

      if (timeoutRef.current <= 0) {
        clearInterval(timerRef.current);
        timerRef.current = null;
        setSessionExpire(true);
        onSessionExpire();
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const handleVisibilityChange = () => {
    if (!isLoggedIn) return;
    if (!document.hidden) {
      startTimer();
    } else {
      stopTimer();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleUserActivity = () => resetTimer();

      startTimer();
      localStorage.setItem("lastActivity", Date.now());

      document.addEventListener("keydown", handleUserActivity);
      document.addEventListener("mousemove", handleUserActivity);
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener("keydown", handleUserActivity);
        document.removeEventListener("mousemove", handleUserActivity);
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        stopTimer();
      };
    } else {
      stopTimer();
      setSessionExpire(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const syncActivity = () => {
      if (!isLoggedIn) return;
      const lastActivity = parseInt(localStorage.getItem("lastActivity") || Date.now(), 10);
      const timeSinceLastActivity = Math.floor((Date.now() - lastActivity) / 1000);

      timeoutRef.current = initialTimeout - timeSinceLastActivity;

      if (timeoutRef.current > 0) {
        setSessionExpire(false);
        resetTimer();
      }
    };

    window.addEventListener("storage", syncActivity);

    return () => {
      window.removeEventListener("storage", syncActivity);
    };
  }, [isLoggedIn, initialTimeout]);

  return { sessionExpire,setSessionExpire };
};

export default useSessionTimeout;




// import { useState, useEffect, useRef } from "react";

// const useSessionTimeout = (isLoggedIn, initialTimeout = 60, onSessionExpire = () => {}) => {
//   const [sessionExpire, setSessionExpire] = useState(false); // Only updates on expiration
//   const timeoutRef = useRef(initialTimeout); // Tracks timeout without triggering re-renders
//   const timerRef = useRef(null);

//   const resetTimer = () => {
//     if (!isLoggedIn) return; // No-op if the user isn't logged in
//     timeoutRef.current = initialTimeout;
//     startTimer();
//   };

//   const startTimer = () => {
//     if (!isLoggedIn || timerRef.current) return; // Only start if not already running
//     timerRef.current = setInterval(() => {
//       timeoutRef.current -= 1;
//       if (timeoutRef.current <= 0) {
//         clearInterval(timerRef.current);
//         timerRef.current = null;
//         setSessionExpire(true);
//         onSessionExpire();
//       }
//     }, 1000);
//   };

//   const stopTimer = () => {
//     clearInterval(timerRef.current);
//     timerRef.current = null;
//   };

//   const handleVisibilityChange = () => {
//     if (!isLoggedIn) return;
//     if (!document.hidden) {
//       stopTimer();
//     } else {
//       startTimer();
//     }
//   };

//   useEffect(() => {
//     if (isLoggedIn) {
//       const handleUserActivity = () => resetTimer();

//       startTimer();
//       document.addEventListener("keydown", handleUserActivity);
//       document.addEventListener("mousemove", handleUserActivity);
//       document.addEventListener("visibilitychange", handleVisibilityChange);

//       return () => {
//         document.removeEventListener("keydown", handleUserActivity);
//         document.removeEventListener("mousemove", handleUserActivity);
//         document.removeEventListener("visibilitychange", handleVisibilityChange);
//         stopTimer();
//       };
//     } else {
//       stopTimer();
//       setSessionExpire(false);
//     }
//   }, [isLoggedIn]);

//   return { sessionExpire, resetTimer };
// };

// export default useSessionTimeout;
