import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/auth/Login";
import { Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CustomPopup from "./components/CustomPopup";
import Blog from "./screens/Blog";
import useSessionTimeout from "./Hooks/useSessionTimeout";
import Testimonial from "./screens/Testimonial";
import AddTestimonial from "./screens/Testimonial/AddTestimonial";
import AddBlog from "./screens/Blog/AddBlog";
import Queries from "./screens/Queries";

function App() {
  const [sessionExpire1, setSessionExpire1] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const location = useLocation();

  // useEffect(() => {
  //   if (user === null) {
  //     if (location.pathname !== "/login") {
  //       navigate("/login");
  //     }
  //     return;
  //   }
  //   if (location.pathname !== "/") {
  //     navigate("/");
  //   }
  // }, []);

  const handleSessionExpire = () => {
    localStorage.clear();
    navigate("/login");
    setSessionExpire1(true);
  };
  const { sessionExpire } = useSessionTimeout(
    user != null,
    600,
    handleSessionExpire
  );

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {user?.role == "superadmin" && (
            <Route path="/" element={<Layout />}>
              <Route path="blog" element={<Blog />} />
              <Route index element={<Queries />} />
              <Route path="add-blog" element={<AddBlog />} />
              <Route path="testimonial" element={<Testimonial />} />
              <Route path="add-testimonial" element={<AddTestimonial />} />
            </Route>
          )}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <CustomPopup
        isOpen={sessionExpire1}
        onClose={() => {
          setSessionExpire1(false);
          localStorage.clear();
          window.sessionStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }}
        title={"Alert"}
        mess={"Your session is expired please Login"}
        single_button={true}
        onClick={() => {
          setSessionExpire1(false);
          window.sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem("user");
          window.location.reload();
        }}
        b_name={"OK"}
      />
    </>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#ffbf00"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;
