import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

const CustomInput = React.memo(
  ({
    label1,
    label2,
    type1,
    type2,
    placeholder1,
    placeholder2,
    value1,
    value2,
    onChange1,
    onChange2,
    mt1,
    mt2,
    info1,
    info2,
    error1,
    error2,
    isInvalid1,
    isInvalid2,
    left1,
    left2,
    single,
    item,
    onlyone,
    area1,
    area2,
    right,
    max1,
    min1,
    max2,
    min2,
    text1,
    text2,
    textonly1,
    textonly2,
    maxL1,
    maxL2,
    autoFocus1,
    rightButton1,
    rightButton2,
    errorBorder1,
    errorBorder2,
    disabled1,
    disabled2,
    star1,
  }) => {
    const initialRef = React.useRef(null);
    const [isLarger] = useMediaQuery("(min-width: 800px)");

    return (
      <Stack flexDirection={["column", "column", "row"]} gap={3} flex={1}>
        {onlyone ? (
          <FormControl mt={mt1 || 2} flex={onlyone ? "" : 1}>
            <FormLabel marginBottom={2}>{label1}</FormLabel>
            <InputGroup>
              {left1 && <InputLeftAddon children={left1} />}
              {textonly1 ? (
                <Text
                  bg={"#00000008"}
                  flex={1}
                  w={"full"}
                  py={2}
                  px={3}
                  borderRadius={5}
                  minH={10}
                >
                  {value1}
                </Text>
              ) : area1 ? (
                <Textarea
                  ref={initialRef}
                  placeholder={placeholder1}
                  type={type1}
                  isInvalid={isInvalid1}
                  errorBorderColor="red.300"
                  value={value1}
                  onChange={onChange1}
                  borderColor={errorBorder1 && "#f00"}
                />
              ) : (
                <Input
                  ref={initialRef}
                  placeholder={placeholder1}
                  type={type1}
                  isInvalid={isInvalid1}
                  errorBorderColor="red.300"
                  value={value1}
                  onChange={onChange1}
                  max={max1}
                  min={min1}
                  maxLength={maxL1}
                  autoFocus={autoFocus1}
                  borderColor={errorBorder1 ? "#f00" : "gray.100"}
                  disabled={disabled1}
                />
              )}
              {right && <InputRightElement>{right}</InputRightElement>}
            </InputGroup>
            {error1 && (
              <Text fontSize={"sm"} color={"#f00"}>
                {error1}
              </Text>
            )}
            {info1 && (
              <Text fontSize={"sm"} mb={0}>
                {info1}
              </Text>
            )}
          </FormControl>
        ) : (
          <>
            <FormControl mt={mt1 || 2} flex={onlyone ? "" : 1}>
              <FormLabel marginBottom={2}>
                {label1}
                {star1}
              </FormLabel>
              <InputGroup>
                {left1 && <InputLeftAddon children={left1} />}
                {textonly1 ? (
                  <Flex
                    bg={"#00000008"}
                    flex={1}
                    w={"full"}
                    py={2}
                    px={3}
                    borderRadius={5}
                    minH={10}
                  >
                    <Text flex={1}>{value1}</Text>
                    {rightButton1}
                  </Flex>
                ) : area1 ? (
                  <Textarea
                    ref={initialRef}
                    placeholder={placeholder1}
                    type={type1}
                    isInvalid={isInvalid1}
                    errorBorderColor="red.300"
                    value={value1}
                    onChange={onChange1}
                    borderColor={errorBorder1 ? "#f00" : "gray.100"}
                  />
                ) : text1 ? (
                  <Text
                    m={0}
                    bg={"#d2d2d2"}
                    flex={1}
                    borderRadius={7}
                    paddingBlock={2}
                    paddingInline={3}
                    minH={10}
                  >
                    {value1}
                  </Text>
                ) : (
                  <Flex w={"full"} gap={2} align={"center"}>
                    <Input
                      ref={initialRef}
                      placeholder={placeholder1}
                      type={type1}
                      isInvalid={isInvalid1}
                      errorBorderColor="red.300"
                      value={value1}
                      onChange={onChange1}
                      max={max1}
                      min={min1}
                      maxLength={maxL1}
                      flex={1}
                      borderColor={errorBorder1 ? "#f00" : "gray.100"}
                      disabled={disabled2}
                    />
                    {rightButton1}
                  </Flex>
                )}
              </InputGroup>
              {info1 && (
                <Text fontSize={"sm"} mb={0}>
                  {info1}
                </Text>
              )}
              {error1 && (
                <Text fontSize={"sm"} color={"#f00"}>
                  {error1}
                </Text>
              )}
            </FormControl>
            {single ? (
              <Stack flex={1} spacing={0}>
                {item}
              </Stack>
            ) : (
              <FormControl mt={mt2 || 2} flex={1}>
                <FormLabel marginBottom={2}>{label2}</FormLabel>
                <InputGroup>
                  {left2 && <InputLeftAddon children={left2} />}
                  {textonly2 ? (
                    <Flex
                      bg={"#00000008"}
                      flex={1}
                      w={"full"}
                      py={2}
                      px={3}
                      borderRadius={5}
                      minH={10}
                    >
                      <Text flex={1}>{value2}</Text>
                      {rightButton2}
                    </Flex>
                  ) : area2 ? (
                    text2 ? (
                      <Text
                        m={0}
                        bg={"#d2d2d2"}
                        flex={1}
                        borderRadius={7}
                        paddingBlock={2}
                        paddingInline={3}
                        minH={10}
                      >
                        {value2}
                      </Text>
                    ) : (
                      <Textarea
                        ref={initialRef}
                        placeholder={placeholder2}
                        type={type2}
                        isInvalid={isInvalid2}
                        errorBorderColor="red.300"
                        value={value2}
                        onChange={onChange2}
                        borderColor={errorBorder2 ? "#f00" : "gray.100"}
                      />
                    )
                  ) : text2 ? (
                    <Text
                      m={0}
                      bg={"#d2d2d2"}
                      flex={1}
                      borderRadius={7}
                      paddingBlock={2}
                      paddingInline={3}
                      minH={10}
                    >
                      {value2}
                    </Text>
                  ) : (
                    <Flex w={"full"} gap={2} align={"center"}>
                      <Input
                        ref={initialRef}
                        placeholder={placeholder2}
                        type={type2}
                        isInvalid={isInvalid2}
                        errorBorderColor="red.300"
                        value={value2}
                        onChange={onChange2}
                        max={max2}
                        min={min2}
                        maxLength={maxL2}
                        borderColor={errorBorder2 ? "#f00" : "gray.100"}
                      />
                      {rightButton2}
                    </Flex>
                  )}
                </InputGroup>
                {info2 && (
                  <Text fontSize={"sm"} mb={0}>
                    {info2}
                  </Text>
                )}
                {error2 && (
                  <Text fontSize={"sm"} color={"#f00"}>
                    {error2}
                  </Text>
                )}
              </FormControl>
            )}
          </>
        )}
      </Stack>
    );
  }
);

export default CustomInput;
